import { Controller } from "@hotwired/stimulus"
import { Popover } from "bootstrap"
import { useClickOutside } from "stimulus-use"


export default class extends Controller {
  static values = {
    content: String,
    placement: { type: String, default: "right" },
    trigger: { type: String, default: "manual" },
    cache: { type: Boolean, default: true },
    cssClass: String,
    viewId: String
  }

  static targets = ["content"]

  connect() {
    this.popover = new Popover(this.element, this.options)
    this.isVisible = false
    this.isVisibleFirstTime = true
    this.updateContent = false
    useClickOutside(this, { element: this.element })
  }

  clickOutside(event) {
    if (event.target.closest(".popover")) { return }
    this.hidePopover()
  }

  toggle(event) {
    event.preventDefault()
    this.togglePopover()
  }

  togglePopover() {
    if (this.isVisible) {
      this.hidePopover()
    } else {
      this.showPopover()
    }
  }

  hidePopover() {
    if(!this.isVisible) { return false }
    this.isVisible = false
    this.popover.hide()
  }

  showPopover() {
    if(this.isVisible) { return false }
    this.popover.show()

    if (this.updateContent || !this.isVisibleFirstTime && !this.cacheValue) {
      this.popover.setContent()
    }

    this.isVisible = true
    this.isVisibleFirstTime = false
    this.updateContent = false
    this.viewIdValue = this.popover.tip.id

    window.popover = this.popover
  }


  updatePopover() {
    console.log("update popover")
    this.popover.update()
  }

  get options() {
    return {
      content: this.content.bind(this),
      trigger: this.triggerValue,
      html: true,
      sanitize: false,
      placement: this.placementValue,
      customClass: this.cssClassValue,
      animation: false,
      template: this.template
    }
  }

  get template() {
    return `
      <div class="popover" role="tooltip" data-controller="popover-view">
        <div class="popover-arrow"></div>
        <h3 class="popover-header"></h3>
        <div class="popover-body"></div>
      </div>`
  }

  content() {
    return this.contentValue || this.contentTarget.innerHTML
  }
}
