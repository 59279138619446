import { Controller } from "@hotwired/stimulus"
import { useResize } from "stimulus-use"

export default class extends Controller {

  connect() {
    useResize(this)
  }

  resize() {
    this.popoverController.updatePopover()
  }

  hide() {
    this.popoverController.hidePopover()
  }

  updateContent() {
    this.popoverController.updateContent = true
  }

  get popoverController() {
    return this.application.getControllerForElementAndIdentifier(this.popoverElement, 'popover')
  }

  get popoverElement() {
    return document.querySelector(this.popoverSelector)
  }

  get popoverSelector() {
    return `[data-popover-view-id-value=${this.element.id}]`
  }
}

